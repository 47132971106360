
export default {
  name: 'LandingFormats',
  props: {
    isHome: {
      type: Boolean,
      default: false
    },
    FooterApi: {
      type: Object,
      default() {}
    },
    pageStructure: {
      type: Array,
      default() {}
    },
    pageConfiguration: {
      type: Array,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#fff'
    },
    txColors: {
      type: String,
      default: '#000'
    }
  },
  components: {
    NServices: () => import('@/components/globals/NServices'),
    FullBanner: () => import('@/components/banners/Full-banners'),
    SimpleBanner: () => import('@/components/banners/Simple-banners'),
    DoubleBanner: () => import('@/components/banners/Double-banners'),
    ThreeBanner: () => import('@/components/banners/Three-banners'),
    ShortcutFilters: () => import('@/components/landing/Shortcut-filters'),
    CarouselProductsFilter: () => import('@/components/carrossel/products-banner-filters'),
    CarouselBrands: () => import('@/components/carrossel/brands'),
    CarouselProducts: () => import('@/components/carrossel/products'),
    CountDown: () => import('@/components/landing/countdown'),
    Logo: () => import('@/components/landing/logo'),
    ListBrands: () => import('@/components/landing/listbrands'),
    FormSalesforce: () => import('@/components/landing/form'),
    ListItens: () => import('@/components/landing/listitens'),
    Simple: () => import('@/components/landing/Simple'),
    ConverterLenses: () => import('@/components/globals/ConverterLenses'),
    FormWorkUs: () => import('@/components/globals/FormWorkUs'),
    VFooterForm: () => import('@/components/Footer/VFooter-form'),
    Wrapper: () => import('@/components/Structure/Wrapper')
  },
  data: () => ({
    showAllComponents: false,
    converterLenses: false,
    formWorkUs: false,
    formDesk: false,
    timerDelay: {
      p: 3000,
      m: 3000,
      g: 3000
    },
    layoutService: {
      bg: '#fafafa',
      txcolor: '#0d153d'
    }
  }),
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    if (this.$route.path.indexOf('/conversao-oculos-lentes-de-contato') > -1) {
      this.converterLenses = true
    }
    if (this.$route.path.indexOf('/trabalhe-conosco') > -1) {
      this.formWorkUs = true
    }
    if (this.$route.path.indexOf('/fale-conosco') > -1) {
      this.formDesk = true
    }
    for (const conf of this.pageConfiguration) {
      if (conf.type === 'temporizador_banners') {
        this.timerDelay.p = conf.configuration.p
        this.timerDelay.m = conf.configuration.m
        this.timerDelay.g = conf.configuration.g
      }
      if (conf.type === 'layout_service') {
        this.layoutService.bg = conf.configuration.bg
        this.layoutService.txcolor = conf.configuration.txcolor
      }
    }
    this.addScrollToBody()
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 140) {
        this.showAllComponents = true
      }
    },
    showComponentService(item) {
      if (item === 0) {
        return true
      }
    },
    showComponent(item) {
      if (item < 3) {
        return true
      } else if (item >= 1 && this.showAllComponents) {
        return true
      } else if (item >= 1 && !this.showAllComponents) {
        return false
      }
    },
    addScrollToBody(){
      const body = document.querySelector('body')
      body.onscroll = () => { this.showAllComponents = true }
    }
  }
}
